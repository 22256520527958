exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-directors-js": () => import("./../../../src/templates/directors.js" /* webpackChunkName: "component---src-templates-directors-js" */),
  "component---src-templates-doctors-js": () => import("./../../../src/templates/doctors.js" /* webpackChunkName: "component---src-templates-doctors-js" */),
  "component---src-templates-homage-js": () => import("./../../../src/templates/homage.js" /* webpackChunkName: "component---src-templates-homage-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-doctor-js": () => import("./../../../src/templates/single-doctor.js" /* webpackChunkName: "component---src-templates-single-doctor-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

